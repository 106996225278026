<template>
  <div>
    <list
      ref="refLaboralDedicationList"
      sort-by="EsquemaPagoTipoId"
      :refetch-records="fetchSchemeTypePayments"
      refetch-records-name="esquemaPagoTipos"
      :actions="actions"
      key-field="esquemaPagoTipoId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteSchemeTypePayment"
      :filters.sync="filters"
    />
  </div>
</template>
<script>

import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import humanResources from '@/services/humanresources.service'

export default {
  components: {
    List,
  },
  setup() {
    /* Refs */
    const refLaboralDedicationList = ref(null)
    /* Services */
    const {
      fetchSchemeTypePayments,
      deleteSchemeTypePayment,
    } = humanResources()
    /* Data */
    const tableColumns = [
      {
        label: i18n.t('humanResources.laboralDedication.columns.name'),
        key: 'nombre',
      },
      {
        label: i18n.t('humanResources.laboralDedication.columns.description'),
        key: 'descripcion',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'create',
        aclResource: 'DedicacionLaboral',
        routeName: 'apps-human-resources-scheme-type-payment-edit',
        params: ['esquemaPagoTipoId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'DedicacionLaboral',
        params: ['esquemaPagoTipoId'],
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        routeName: 'apps-human-resources-scheme-type-payment-add',
        label: i18n.t('Routes.SchemeTypePaymentAdd'),
        aclAction: 'create',
        aclResource: 'DedicacionLaboral',
        icon: 'PlusCircleIcon',
      },
    ])
    const filters = ref([])
    return {
      refLaboralDedicationList,
      tableColumns,
      tableActions,
      actions,
      filters,
      fetchSchemeTypePayments,
      deleteSchemeTypePayment,
    }
  },
}
</script>
